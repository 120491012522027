import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Content from './Content'
import ShareLinks from './ShareLinks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

const BlogPostTemplate = ({
  content,
  contentComponent,
  slug,
  tags,
  description,
  date,
  readingTime,
  thumbnail,
  title,
  url,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <Helmet>
        <meta property="og:url"                content={url+slug} />
        <meta property="og:type"               content="article" />
        <meta property="og:title"              content={title} />
        <meta property="og:description"        content={description} />
        <meta property="og:image"              content={url+thumbnail.childImageSharp.fluid.src} />
      </Helmet>
      <div className="container content">
        <div className="columns">
          <div className="column is-7 is-offset-2">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <p><small className="accent-color">Posted on {date} | {readingTime}</small></p>
              <div class="dropdown is-hoverable" style={{marginBottom: '0'}}>
                <div class="dropdown-trigger">
                  <button class="button is-primary" aria-haspopup="true" aria-controls="dropdown-menu4">
                    <span>Share</span>
                    <span className="icon is-small">
                      <FontAwesomeIcon icon={faAngleDown} />
                    </span>
                  </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu4" role="menu">
                  <div class="dropdown-content">
                    <ShareLinks slug={slug} />
                  </div>
                </div>
              </div>

                <Link style={{ marginLeft: '1rem'}} className="button is-dark" to="/latest-updates">See All Posts</Link>
            {/* <hr/> */}
            <Img fluid={thumbnail.childImageSharp.fluid} alt="" style={{ maxWidth: '600px', margin: '1rem auto 2rem'}} />
            <PostContent content={content} />
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h4>Tags</h4>
                <ul className="taglist">
                  {tags.map(tag => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.string.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.instanceOf(Helmet),
}

export default BlogPostTemplate
